import React from 'react';

const Table = ({ children, className }) => {
  return (<table className={`table-auto w-full ${className}`}>{children}</table>);
}

Table.Head = ({ children }) => {
  return (<thead>{children}</thead>);
}

Table.Body = ({ children }) => {
  return (<tbody>{children}</tbody>);
}

Table.Row = ({ children, className }) => {
  return (<tr className={className}>{children}</tr>);
}

Table.Td = ({ children, className, ...props }) => {
  return (<td className={`border px-2 py-1 ${className}`} {...props}>{children}</td>);
}

Table.Th = ({ children, ...props }) => {
  return (<th className="border px-2 py-1" {...props}>{children}</th>);
}

export default Table;
